(function($) {
    $('.faqs').each(function(){
        $(this).find('.faqs__item').each(function(){
            $(this).find('.faqs__item-title').on('click',function(){
                $(this).toggleClass('open');
                $(this).next('.faqs__item-content').slideToggle('medium');
            })
        });
    });
    
})(jQuery);